//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-952:_8804,_3952,_4252,_2932,_8440,_1808,_4224,_912;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-952')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-952', "_8804,_3952,_4252,_2932,_8440,_1808,_4224,_912");
        }
      }catch (ex) {
        console.error(ex);
      }